// React
import React from "react";
import { useState, useEffect, useCallback, useMemo } from "react";

// Antd
import { Table, Space, notification, Button, Input, Row, Form, Modal, DatePicker, InputNumber } from "antd";
import { SearchOutlined } from "@ant-design/icons";

// Components
import FilterControls from "./FilterControls";

// Plugins
import Highlighter from "react-highlight-words";

// Dates
import moment from "moment";

// Services
import CompraService from "../services/compraservice";

function ReportCompras() {
  const compraService = useMemo(() => new CompraService(), []);
  const [dataSource, setdataSource] = useState([]);
  const [dateStart, setDateStart] = useState("");
  const [dateEnd, setDateEnd] = useState("");
  const [filterText, setFilterText] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchColumn, setSearchColumn] = useState("");
  const [originalDatasource, setOriginalDatasource] = useState(0);

  const [currentCompra, setCurrentCompra] = useState({});

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);

  const [matches, setMatches] = useState(window.matchMedia("(min-width: 1868px)").matches);

  const getReportData = useCallback(() => {
    compraService
      .getAllCompras(page, size, dateStart, dateEnd, filterText)
      .then((response) => {
        const { content, totalElements } = response.data;
        setTotalRows(totalElements);
        setdataSource(content);
        setOriginalDatasource(content);
      });
  }, [compraService, filterText, page, size, dateStart, dateEnd]);

  useEffect(() => {
    getReportData();
    const handler = e => {
    
      setMatches(e.matches);
    }
    window.matchMedia("(min-width: 1868px)").addEventListener('change', handler);
  }, [getReportData]);

  let searchInput = undefined;

  const onChangePage = (p, s) => {
    setSize(s);
    setPage(p - 1);
  };

  const onChangeDate = (dateStr, dp) => {
    if (dp === "start") {
      setDateStart(dateStr);
    } else {
      setDateEnd(dateStr);
    }
  };

  const getCallback = () => {
    if (dateStart.length === 0 || dateEnd.length === 0) {
      notification["error"]({
        message: "Error",
        description: "Las fechas no son correctas",
      });
      return;
    }
    getReportData();
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              if(dataIndex==='orden'){
                setFilterText(selectedKeys[0]);
              }
              setSearchColumn(dataIndex);
            }}
          >
            Filtrar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) =>
      searchColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText, filterText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setdataSource(originalDatasource);
    setSearchText("");
    setFilterText("");
  };

  const handleDownload = (response) => {
    console.log(response);
    const temp = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = temp;
    link.setAttribute('download', response.headers["x-suggested-filename"]); //or any other extension
    document.body.appendChild(link);
    link.click();
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Cliente",
      dataIndex: "cliente",
      key: "cliente",
    },
    {
      title: "Fecha",
      dataIndex: "createdat",
      key: "createdat",
      sorter: (a, b) => moment(a.createdat).unix() - moment(b.createdat).unix(),
      sortDirections: ["descend", "ascend"],
    },
    {
      title: "Servicio",
      dataIndex: "servicio",
      key: "servicio",
    },
    {
      title: "Importe",
      dataIndex: "importe",
      key: "importe",
    },
    {
      title: "Cantidad",
      dataIndex: "cantidad",
      key: "cantidad",
    },
    {
      title: "Orden",
      dataIndex: "orden",
      key: "orden",
      sorter: (a, b) => String(a.orden).localeCompare(String(b.orden)),
      sortDirections: ["descend", "ascend"],
      ...getColumnSearchProps("orden"),
    },
    {
      title: "Costo unitario",
      dataIndex: "costounitario",
      key: "costounitario",
      sorter: (a, b) => String(a.costounitario).localeCompare(String(b.costounitario)),
      sortDirections: ["descend", "ascend"],
      ...getColumnSearchProps("costounitario"),
    },
    {
      title: 'Descargar',
      dataIndex: 'operation',
      render: (_, e) =>{
          return (
              dataSource.length >= 1 ? (
                  <Space direction={matches ? "horizontal" : "vertical"}>
                  
            <Button type="primary" onClick={()=>{compraService.downloadOrden(e.id,"pdf").then(response=>handleDownload(response))}}>Descargar PDF</Button>
            <Button type="primary" onClick={()=> onClickRow(e) }>Detalle</Button>
          </Space>
        ) : null)
      }
    },
  ];

  const onFinish = (values) => {
    const id = values.id ?? 0;
    console.log(id);
    values = {
        createdat: values.fecha.format("YYYY-MM-DD"),
        cliente: values.cliente,
        servicio: values.servicio,
        importe: values.importe,
        cantidad: values.cantidad,
        orden: values.orden
    };
    console.log('Success:', values);
    compraService.saveCompra(id, values).then(r => {
      setIsModalVisible(false);
      if(id === 0){
        setdataSource([...dataSource, r.data]);
      }else{
        setdataSource(dataSource.map(e => {
          if(e.id === id){
            return {...values, id};
          }else{
            return e;
          }
        }));
      }
      notification.success({message: "Guardado", description: "La compra fue guardada con éxito."})
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onClickRow = (row) => {
    setCurrentCompra({...row, fecha: moment(row.createdat)});
    console.log({...row, fecha: row.createdat.split(' ')[0], hora: row.createdat.split(' ')[1]});
    setIsModalVisible(true);
  };

  return (
    <div className="App">
      <h2>Reporte de compra de unidades</h2>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Row>
          <Space style={{marginBottom: 16}} size="large" direction="horizontal">
            <Button type="primary" onClick={() => setIsModalVisible(true)}>Agregar</Button>
            <FilterControls onChangeDate={onChangeDate} getCallback={getCallback} />
          </Space>
        </Row>
        <Table
          dataSource={dataSource}
          columns={columns}
          rowKey="id"
          pagination={{
            pageSize: size,
            onChange: onChangePage,
            total: totalRows,
          }}
        />
      </Space>
      <Modal title="Datos de Compra" visible={isModalVisible} footer={null} onCancel={()=>setIsModalVisible(false)} >
        <Form
        name="savecompra"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 14 }}
        initialValues={currentCompra}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >

      <Form.Item
          label="ID"
          name="id"
          style={{ display: 'none' }}
        >
          <Input maxLength={10} type="hidden" />
        </Form.Item>
        
        <Form.Item
          label="Cliente"
          name="cliente"
          rules={[{ required: true, min: 5, max: 500,  message: 'Introduce un cliente válido' }]}
        >
          <Input maxLength={500} />
        </Form.Item>


        <Form.Item
          label="Fecha"
          name="fecha"
          rules={[{ required: true, message: 'Introduce una fecha' }]}
        >
          <DatePicker placeholder="Fecha" style={{width: "100%"}}/>
        </Form.Item>

        <Form.Item
          label="Servicio"
          name="servicio"
          rules={[{ required: true, min: 5, max: 500, message: 'Introduce un servicio válido'}]}
        >
          <Input maxLength={500} />
        </Form.Item>


        <Form.Item
          label="Importe"
          name="importe"
          rules={[{ required: true, message: 'Introduce un importe válido'}]}
        >
          <InputNumber 
            min={1}
            precision={2}
            step={0.1}
            max={9999999}
            style={{width: "100%"}}/>
        </Form.Item>



        <Form.Item
          label="Cantidad"
          name="cantidad"
          rules={[{ required: true, message: 'Introduce una cantidad válida'}]}
        >
          <InputNumber 
            min={1}
            precision={2}
            step={0.1}
            max={9999999}
            style={{width: "100%"}}/>
        </Form.Item>

        <Form.Item
          label="Orden"
          name="orden"
          rules={[{ required: true, min: 1, max: 999999, message: 'Introduce un número de orden válido', type: 'number'}]}
        >
          <InputNumber style={{width: "100%"}} maxLength={6} />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Guardar
          </Button>
        </Form.Item>
      </Form>
      </Modal>
    </div>
  );
}

export default ReportCompras;
