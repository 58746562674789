import React, { useState } from "react";
import { Form, Button, Space, notification } from "antd";
import { useAuth } from "../contexts/AuthContext";
import { Link, Redirect } from "react-router-dom";

import { Image, Row, Card, Input } from "antd";

import TokenService from "../services/tokenservice";

export default function Login() {
  const tokenService = new TokenService();
  const { login, currentUser, setCurrentUser } = useAuth();

  const [loading, setLoading] = useState(false);
  async function handleSubmit(values) {
    try {
      setLoading(true);

      login(values.email, values.password)
        .catch((e) => {
          console.log("errorlogin", e);
        })
        .then((res) => {
          if (res) {
            if (!res.user.emailVerified) {
              notification["error"]({
                message: "Error",
                description: "Correo no verificado.",
              });
            }
            if (res.user.emailVerified) {
              setCurrentUser(res.user);
            }
          } else {
            notification["error"]({
              message: "Error",
              description: "Error al iniciar sesión.",
            });
          }
        });
    } catch (e) {
      console.log(e);
      notification["error"]({
        message: "Error",
        description: "Error al iniciar sesión.",
      });
    } finally {
      setLoading(false);
    }
  }

  return currentUser && tokenService.getCurrentToken() ? (
    <Redirect to="/" />
  ) : (
    <>
      <div className="w-100" style={{ maxWidth: "400px", margin: "auto" }}>
        <Card>
          <h2 className="text-center mb-4">Entrar</h2>
          <Row justify="center">
            <Image
              width={200}
              src="logo.png"
              preview={false}
            />
          </Row>

          <Form
            onFinish={handleSubmit}
            name="login"
            initialValues={{
              remember: true,
            }}
          >
            <Space
              direction="vertical"
              size="small"
              style={{ marginTop: 20, width: "100%", textAlign: "center" }}
            >
              <Form.Item name="email">
                <Input placeholder="Email" type="email" required />
              </Form.Item>
              <Form.Item name="password">
                <Input placeholder="Password" required type="password" />
              </Form.Item>
              <Form.Item>
                <Button disabled={loading} type="dashed" htmlType="submit">
                  Entrar
                </Button>
              </Form.Item>
            </Space>
          </Form>
          <div className="w-100 text-center mt-3">
            <Link to="/forgot-password">¿Olvidaste tu clave?</Link>
          </div>
        </Card>
        <div className="w-100 text-center mt-2">
          ¿Necesitas una cuenta? <Link to="/signup">Registrate</Link>
        </div>
      </div>
    </>
  );
}
